import * as React from "react"
import { Link } from "gatsby"
import { Layout } from "../components/Layout"



// markup
const NotFoundPage = () => {
  return (
    <Layout title={"404 - Reviti"}>
      <div className="w-full">
        

        <div className="h-screen flex-grow flex flex-col relative items-center">
        
          <div className="w-1/4 py-7 top-0">
            <div className="w-full image-wrapper relative top-0">
         
              <img src="/megaphone/megaphone.webp" alt="Main Header" className="main-1" />
              <object type="image/svg+xml" data="/megaphone/megaphone.svg" className="couple-animation"></object>
              <img src="/megaphone/megaphone-overlay.webp" alt="Main Header Split" className="split-1" />
            </div>
          </div>
        
        
        
          <h1 className="font-semibold">On no! Error 404</h1>
        
          <span className="bg-primarygreen w-20 min-h-4 md:min-h-6 my-4"></span>
        
          <p className="">
            The page you are looking for isn't found. We suggest you go back
            home.
          </p>
        
          <Link to="/" className="my-4">
            <button type="button" className="btn-primary">
              <span>Back to homepage</span>
            </button>
          </Link>
        </div>
            </div>
        
      </Layout>
  )
}

export default NotFoundPage
